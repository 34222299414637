@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Just+Another+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jua&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

/* font-family: "Just Another Hand", cursive; */
/* font-family: "Inter", sans-serif; */
body {
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
legend {
  font-family: "Just Another Hand", cursive;
}

p,
label,
span,
a,
h4,
h5,
h6,
button,
.accord-title,
.accord-content {
  font-family: "Inter", sans-serif !important;
}

.banner-section-landing {
  /* height: 100vh; */
  position: relative;
  overflow: hidden;
}

.banner-section-landing video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-section-landing .MuiGrid-root.MuiGrid-container {
  position: relative;
  padding-top: 220px;
  padding-bottom: 50px;
  align-items: flex-end;
}

.wreath.who-is-bubudu-wreath img {
  transform: rotate(180deg);
}

.presale-section.who-is-bubudu h2.landing-page-heading-2 {
  margin-bottom: 0px;
}

.presale-section.who-is-bubudu {
  padding: 80px 0;
}

legend.presale-form-footer-powered {
  color: rgba(0, 0, 0, 1);
  font-size: 35px;
}

button.active-btn {
  background: #000 !important;
  color: #fff !important;
}

.who-is-bubu-dudu {
  max-width: 1200px;
  margin: 160px auto 0;
  position: relative;
}

img.whobuduboard {
  width: 600px !important;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 68px;
  height: 395px;
}

.who-is-budu-img {
  position: relative;
}

.who-is-budu-video {
  position: absolute;
  width: 559px;
  height: 662px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -278px;
  margin-top: -323px;
}

.who-is-budu-video video {
  width: 100%;
  height: 100%;
  transform: rotate(-3deg);
}

.buduwhotop {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  margin-top: -40px;
}

.buduwhotop img {
  width: 400px;
}

.who-is-bubu-dudu {
  margin-top: -140px;
}

.who-is-budu-img img {
  width: 100%;
}

.MuiStack-root.presale-coin-selected-btns.equal-width .presale-plan-in-button {
  width: 50%;
}

.MuiStack-root.presale-coin-selected-btns.equal-width .presale-plan-in-button button {
  width: 100%;
}

.MuiStack-root.presale-coin-selected-btns.equal-width .presale-plan-in-button:first-child button {
  background: #000;
  color: #fff;
}

.MuiStack-root.presale-coin-selected-btns.equal-width .presale-plan-in-button:nth-child(2) button {
  background: transparent;
  color: #fff;
  border: 2px solid rgba(238, 232, 250, 1);
}

.MuiStack-root.presale-coin-selected-btns.equal-width {
  gap: 20px;
  justify-content: center;
}

p.font-caps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.flex-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 13px;
}

.text-field-box-inner fieldset {
  border: 2px solid rgba(238, 232, 250, 1) !important;
}

.text-field-box-inner input::placeholder,
.text-field-box-inner input,
.text-field-box-inner fieldset {
  color: #fff;
  opacity: 1;
}

.text-field-box-inner-inner {
  position: relative;
}

.text-field-box-inner-inner img {
  position: absolute;
  right: 10px;
  top: 10px;
}

.banner-text h1 {
  font-size: 175px;
  font-weight: 400;
  margin: 0px;
  line-height: 112px;
  color: #fff;
  letter-spacing: 5px;
}

.banner-text {
  position: relative;
  text-align: right;
}

.budu-flex {
  position: relative;
}

.banner-logo {
  display: flex;
  align-items: center;
}

.banner-logo img {
  border-radius: 20.28px;
  background: #fff;
  box-shadow: 0px 39.336px 61.189px 0px rgba(255, 122, 0, 0.5);
  padding: 15px 20px;
  width: 160px;
  position: absolute;
  right: 25px;
  top: 120px;
}

.banner-img img {
  height: 400px;
}

.banner-img {
  margin-top: -65px;
}

.banner-text label {
  color: rgba(0, 0, 0, 1);
  font-family: "Just Another Hand", cursive !important;
  font-size: 27px;
  position: absolute;
  left: 50%;
  top: -50px;
  margin-left: -34px;
}

.banner-text span {
  font-family: "Jua", sans-serif, cursive !important;
  color: #000;
  font-size: 23px;
}

.presale-form {
  background: rgba(255, 122, 0, 1);
  padding: 15px;
  border-radius: 20px;
  color: #fff;
  position: relative;
}

.presale-form-for-presale {
  width: 400px;
}

.presale-form .peresaleicon3 {
  position: absolute;
  top: -82px;
  left: -15px;
}

.peresaleicon3 img {
  height: 150px;
}

.mobile-btns {
  display: none !important;
}

ul.header-menu li a:hover {
  color: rgba(57, 89, 255, 1);
  border-bottom: 3px solid rgba(57, 89, 255, 1);
}

.landing-page-main.presale-mail-page.landing-page-main-page div#header-right .presale-plan-in-button {
  display: none;
}

.mobile-btns {
  display: none !important;
}

.logobudu svg {
  display: none;
}

legend.presale-form-head {
  font-size: 30px;
}

.price-increase-time {
  position: relative;
}

span.track-range {
  display: inline-block;
  width: 100%;
  height: 28px;
  border-radius: 35px;
  background: rgba(238, 232, 250, 0.15);
}

.price-increase-time label {
  position: absolute;
  width: 100%;
  left: 0;
  height: 90%;
  /* display: flex; */
  top: 3px;
  z-index: 9;
}

span.range-length {
  display: inline-block;
  /* width: 100%; */
  height: 28px;
  border-radius: 35px;
  background: rgb(0 0 0);
  position: absolute;
  left: 0;
}

span.span-rate-inner {
  display: inline-block;
  padding: 5px 35px;
  background: #ff7a00;
  position: relative;
}

span.span-rate {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

span.span-rate::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: #fff;
  left: 0;
  top: 14px;
}

.MuiStack-root.presale-coin-selected-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 27px;
  gap: 15px;
}

.flex-label.value {
  flex-direction: column;
}

.MuiStack-root.presale-coin-selected-btns button,
.MuiStack-root.presale-coin-selected-btns a {
  background: rgb(238 232 250 / 83%);
  color: #fd7900;
  box-shadow: none;
  padding: 10px 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
}

h2.landing-page-heading-2 {
  font-size: 70px;
  margin: 0px;
  color: rgba(217, 115, 115, 1);
  font-weight: 400;
  margin-bottom: 20px;
}

p.presale-section-para-1,
p.presale-section-para-2,
p.presale-section-para-3 {
  margin-top: 0px !important;
  margin-bottom: 30px;
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto 30px;
  color: rgba(0, 0, 0, 1);
  line-height: 35px;
}

p.presale-section-para-2 {
  margin-bottom: 80px;
}

p.presale-section-para-3 {
  margin-top: 35px !important;
}

.presale-plan {
  background-image: url("./images/presale-plan-bg.webp");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 60px 0;
  background-position: center;
  background-color: #fbe9e8;
}

.presale-plan-in {
  display: flex;
  font-size: 43px;
  position: relative;
  left: 60px;
}

.presale-plan-inner {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: left;
  max-width: 480px;
  margin: 0 auto;
}

.presale-structure {
  padding-top: 120px;
  padding-bottom: 110px;
  background: rgba(83, 166, 224, 1);
}

.presale-structure legend {
  font-size: 36px;
  text-align: left;
  color: #000;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.card-landing.card-common {
  text-align: left;
  background: rgba(251, 233, 232, 1);
  padding: 35px 35px 35px 100px;
  border-radius: 18px;
  box-shadow: 0px 4.27px 48.75px -7.31px rgba(85, 102, 255, 1);
  position: relative;
}

.presale-plan-in-button button,
.presale-plan-in-button a {
  padding: 10px 35px;
  border-radius: 35px;
  box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3);
  background: rgba(57, 89, 255, 1);
}

.presale-plan-in.presale-plan-in-button {
  margin-top: 40px;
}

.card-landing.card-common p {
  margin: 0px;
  line-height: 25px;
  font-size: 16px;
  color: #000;
}

.presale-structure .MuiGrid-root.MuiGrid-container {
  max-width: 1180px;
  margin: 0 auto;
}

legend.phase-even-right {
  text-align: right;
}

.presale-steps-icon {
  position: absolute;
  left: -8px;
  bottom: -6px;
}

.presale-steps-icon img {
  width: 105px;
  height: auto;
}

.presale-steps-icon.peresaleicon2 {
  left: -50px;
  bottom: -20px;
}

.presale-steps-icon.peresaleicon1 {
  left: -74px;
  bottom: -41px;
}

.presale-steps-icon.peresaleicon1 img {
  width: 150px;
}

.presale-steps-icon.peresaleicon2 img {
  width: 120px;
}

.presale-steps-icon.peresaleicon2 {
  left: -58px;
}

h3.presale-structure-3 {
  font-size: 120px;
  font-weight: 400;
  color: #000;
  margin: 0px;
  line-height: 110px;
  position: relative;
}

h3.presale-structure-3 img {
  position: absolute;
  right: 14px;
  top: -136px;
}

.presale-structure.fund-allocation .MuiGrid-root.MuiGrid-container {
  align-items: flex-end;
}

.presale-structure.fund-allocation {
  padding-top: 0;
  margin-top: -280px;
}

.presale-structure.fund-allocation h3.presale-structure-3 {
  position: relative;
  top: -67px;
  text-align: left;
}

.wreath.wreath-top-presale.rotate-left {
  text-align: left;
  position: relative;
  left: -111px;
  top: 113px;
}

.wreath.wreath-top-presale.rotate-left img {
  transform: rotate(45deg);
  /* position: relative; */
  /* left: -111px; */
  /* top: 113px; */
}

.wreath.wreath-top-presale.rotate-right {
  text-align: left;
  top: 150px;
  position: relative;
  left: -140px;
}

.wreath.wreath-top-presale.rotate-right img {
  transform: rotate(137deg);
}

.fund-allocation-process legend {
  text-transform: capitalize;
}

.fund-allocation-process {
  display: flex;
  gap: 20px;
  justify-content: center;
  position: relative;
  padding-top: 110px;
}

.fund-allocation-process-odd-step,
.fund-allocation-process-even-step {
  text-align: center;
}

.fund-allocation-process-even-step {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  position: relative;
  top: -68px;
}

.seven-companies p {
  margin-top: 0px;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.seven-companies {
  padding-top: 80px;
  padding-bottom: 80px;
  background: rgba(57, 89, 255, 1);
}

.seven-companies-inner {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.seven-companies-inner img {
  height: 30px;
}

/** FAQ ***/

.accord-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
  background: #fff;
  color: #000;
  padding: 20px 30px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  text-align: left;
  box-shadow: 0px 15px 25px -10px rgba(20, 23, 24, 0.05);
}

.accord-content {
  font-size: 20px;
  padding: 0px;
  background: #efeeee;
  height: 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.accord-content.show {
  display: block;
  transition: all 0.3s ease-in-out;
  height: auto;
  overflow: visible;
  padding: 20px 30px;
  font-size: 15px;
  text-align: left;
  color: #000;
  line-height: 27px;
}

.accord-title-cont {
  margin-bottom: 30px;
}

.tab-outer ul {
  list-style: none;
  padding-left: 0px;
  margin-top: 0px;
  display: flex;
  gap: 20px;
}

.tab-outer ul li a {
  text-decoration: none;
  padding: 10px 15px;
  color: #000;
  display: inline-block;
}

.tab-content {
  display: none;
}

.active-tabcont {
  display: block;
  padding: 5px 15px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
}

a.active-tab {
  background: #ccc;
  border-radius: 10px;
  font-weight: 700;
}

.faq-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px 0;
}

.faq-section h4 {
  margin-top: 0px;
  font-size: 50px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}

.accordminus-accordplus img {
  width: 30px;
}

/*** footer **/

.footer-section .MuiGrid-root.MuiGrid-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.footer-1 {
  background: rgba(0, 0, 0, 1);
  padding: 35px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
}

.footer-1 h5 {
  margin: 0px;
}

.footer-1 h5 {
  font-size: 32px;
  color: #fff;
  font-weight: 400;
  text-align: left;
  max-width: 300px;
  word-wrap: break-word;
}

.footer-section {
  padding-top: 50px;
}

.subscribe-footer {
  position: relative;
}

.subscribe-footer .MuiFormControl-root.MuiTextField-root {
  background: #fff;
  width: 100%;
  border-radius: 22px;
}

.subscribe-footer button {
  position: absolute;
  right: 0;
  background: #000 !important;
  border: gainsboro;
  box-shadow: none;
  color: rgba(57, 89, 255, 1);
  text-transform: none;
  height: 90%;
  top: 3px;
  right: 2px;
  border-radius: 22px;
  padding-left: 25px;
  padding-right: 25px;
}

.footer-section ul {
  margin: 0px;
  /* padding-left: 0px; */
  list-style: none;
  text-align: left;
  margin-bottom: 40px;
}

.footer-section ul li a {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  display: inline-block;
  margin-bottom: 10px;
}

.footer-section ul li a:hover {
  color: #3959ff;
}

ul.social-icons-list li a {
  display: flex;
  align-items: center;
  height: 60px;
  background: #000;
  width: 60px;
  justify-content: center;
  border-radius: 10px;
}

ul.social-icons-list li a:hover {
  background: #3959ff;
}

ul.social-icons-list {
  padding-left: 0px;
}

ul.social-icons-list li {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*** header ***/

.header-section .MuiGrid-root.MuiGrid-container {
  max-width: 1370px;
  margin: 0 auto;
  align-items: center;
}

div#header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  gap: 20px;
}

div#header-left .logobudu {
  align-items: center;
  display: flex;
}

ul.header-menu {
  padding: 0px;
  list-style: none;
  display: flex;
  gap: 30px;
}

ul.header-menu li a {
  text-decoration: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  display: inline-block;
  padding: 5px 0 !important;
  border-bottom: 3px solid transparent;
}

div#header-right .presale-plan-in-button button {
  padding: 10px 25px;
  background: rgba(57, 89, 255, 1);
}

div#header-left .logobudu {
  align-items: center;
  display: flex;
}

.mobile-menu-close-icon {
  display: none;
}

.header-section .MuiGrid-root.MuiGrid-container {
  padding: 13px 25px;
  background: rgba(241, 243, 250, 0.5);
  border-radius: 12px;
  box-shadow: 0px 27px 42px 0px rgba(64, 64, 64, 0.3);
  backdrop-filter: blur(50px);
}

div#header-right {
  background: transparent;
}

.header-section {
  position: fixed;
  top: 25px;
  left: 0;
  margin-left: 0;
  width: 100%;
  z-index: 999;
}

li.presalemenu {
  position: relative;
  margin-right: 15px;
}

li.presalemenu::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("./images/presale-menu.webp");
  right: -20px;
  background-size: contain;
  top: 5px;
}

/*** presale page style ***/

.What-is-Thorecoin .MuiGrid-root.MuiGrid-container {
  max-width: 1370px;
  margin: 0 auto;
}

.What-is-Thorecoin {
  padding: 30px 0 100px;
}

.What-is-Thorecoin h2,
.What-is-Thorecoin p {
  font-family: "Inter", sans-serif;
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
}

.What-is-Thorecoin p {
  margin-top: 20px;
  margin-bottom: 40px;
}

.What-is-Thorecoin h2 {
  font-size: 48px;
}

.thore-net-card .inner-thorecoin:nth-child(odd) p a {
  color: #3959ff;
}

.thore-net-card .inner-thorecoin:nth-child(even) p a {
  color: #fff;
}

.thore-net-card .inner-thorecoin p button {
  margin: 0px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  font-weight: 400;
  text-transform: capitalize;
}

.thore-net-card .inner-thorecoin:nth-child(odd) p button {
  color: #3959ff;
}

.thore-net-card .inner-thorecoin:nth-child(even) p button {
  color: #e8eaff;
}

.close-btn {
  text-align: end;
  cursor: pointer;
}

.read_more_popup h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #3959ff;
}

.read_more_popup p.servo {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 36px; */
  margin: 0;
  margin-bottom: 10px;
}

.read_more_popup h6 {
  color: #3959ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  margin: 10px 0;
}

.flex-div svg {
  /* transform: rotate(90deg); */
}

.read_more_popup .scroll-div {
  overflow-y: scroll;
  height: 360px;
}

.flex-div {
  display: flex;
  gap: 5px;
  margin-bottom: 30px;
}

.inner-thorecoin strong {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  display: inline-block;
  margin-bottom: 20px;
}

.inner-thorecoin p {
  margin: 0px;
  text-align: left;
  font-size: 14px;
  line-height: 22px;
}

.inner-thorecoin p a {
  text-decoration: none;
  color: #3959ff;
}

.inner-thorecoin {
  padding: 35px !important;
  /* background: #000; */
  background: linear-gradient(47.82deg,
      rgba(42, 61, 237, 0.2) 0%,
      rgba(100, 114, 243, 0.2) 100%);
}

.inner-thorecoin:nth-child(even) {
  background: #000;
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  box-shadow: 0px 7px 80px -12px rgba(85, 102, 255, 1);
  color: #fff;
  position: relative;
  top: 75px;
  transform: scale(1.12);
}

/* .banner-Thorecoin {
  height: 100vh;
  background: url("./images/thorecoinbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 10% -500%;
} */

.banner-Thorecoin .MuiGrid-root.MuiGrid-container,
.exchange-banner .MuiGrid-root.MuiGrid-container {
  max-width: 1370px;
  margin: 0 auto;
  padding: 169px 0 80px;
}

.thorecoin-banner-left.thorecoin-banner-left-equity {
  padding-top: 0px !important;
}

.thorecoin-banner-left.thorecoin-banner-left-equity h3 {
  margin-top: 15px;
}

.banner-Thorecoin .MuiGrid-root.MuiGrid-container h1 {
  font-size: 62px;
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background: -webkit-linear-gradient(25deg,
      rgba(28, 28, 28, 1) 0.25%,
      rgba(59, 71, 83, 0) 99.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.thorecoinlaunchpad {
  text-align: right;
  padding-right: 100px;
  margin-top: 45px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.thorecoin-banner-left {
  padding-top: 50px !important;
}

.thorecoinlaunchpad img {
  cursor: pointer;
}

.presale-form.thorecoin-banner-right-form {
  background: rgba(57, 89, 255, 1);
  width: 400px;
}

div#presale-form-thorecoin-banner-right-form-id {
  position: relative;
}

div#presale-form-thorecoin-banner-right-form-id .presale-form.thorecoin-banner-right-form {
  position: absolute;
  top: 0;
  right: 0;
}

div#presale-form-thorecoin-banner-right-form-id .presale-form.thorecoin-banner-right-form legend.presale-form-head {
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

div#presale-form-thorecoin-banner-right-form-id .MuiStack-root.presale-coin-selected-btns button {
  color: #000;
}

div#presale-form-thorecoin-banner-right-form-id span.span-rate-inner.font-caps {
  background: #3959ff;
}

.MuiStack-root.presale-coin-selected-btns.equal-width.connect-wallet-buy-bnb button {
  color: #fff !important;
}

.MuiStack-root.presale-coin-selected-btns.equal-width.connect-wallet-buy-bnb button {
  color: #fff !important;
}

legend.presale-form-head {
  position: relative;
}

.presale-plan-in-button.card-option-btn img {
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
  height: 25px;
}

.presale-plan-in-button button:hover,
.presale-plan-in-button a:hover {
  background: #000;
  color: #fff !important;
}

legend.presale-form-head .buttonopenclose {
  position: absolute;
  top: 2px;
  right: 5px;
  cursor: pointer;
}

.thorecoin-banner-left a,
.outsmart-inner-thorecoin-left a {
  font-size: 16px;
  color: #fff;
  background: #000;
  border-radius: 16px;
  padding: 15px 30px;
  text-decoration: none;
  display: inline-block;
  margin-top: 60px;
}

.outsmart-inner-thorecoin-left label {
  font-size: 12px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.outsmart-inner-thorecoin-left h2 {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  margin-bottom: 0px;
  margin-top: 15px;
  color: #fff;
}

.What-is-Thorecoin-outsmart .MuiGrid-root.MuiGrid-container {
  max-width: 1070px;
  margin: 0 auto;
  background: url("./images/outsmartbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 70px 60px 70px;
  border-radius: 15px;
  position: relative;
}

.What-is-Thorecoin-outsmart {
  margin: 330px 0 110px;
}

.What-is-Thorecoin-outsmart {
  margin: 260px 0 110px;
}

.outsmartbgimg {
  position: absolute;
  bottom: -14px;
  right: 0;
}

.outsmartbgimg img {
  height: 600px;
}

.What-is-Thorecoin-outsmart.howthrn .MuiGrid-root.MuiGrid-container {
  padding: 0px;
  background: transparent;
  max-width: 1240px;
}

.What-is-Thorecoin-outsmart.howthrn {
  margin-top: 0;
  padding: 100px 0;
  background: url("./images/howthrn.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
}

.What-is-Thorecoin-outsmart.howthrn h3 {
  margin-bottom: 50px;
  font-size: 45px;
  margin-top: 0px;
  font-family: "Inter", sans-serif;
  text-align: center;
  color: #fff;
}

.howthrn-inner-block {
  backdrop-filter: blur(70px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}

.howthrn-inner-block span {
  display: flex;
  width: 48px;
  height: 48px;
  background: rgba(57, 89, 255, 1);
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.howthrn-inner-block .MuiAvatar-root {
  margin: 10px 0px 25px;
}

.howthrn-inner-block label {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 40px;
}

.howthrn-inner-block p {
  margin: 0px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  word-break: break-word;
}

.token-section-heading h3 span {
  font-size: 200px;
  background: -webkit-linear-gradient(rgba(42, 61, 237, 1),
      rgba(100, 114, 243, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 160px;
}

.token-section-heading h3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0px;
  position: relative;
  right: 25px;
  /* box-shadow: 0px 7px 80px -12px rgba(85, 102, 255, 1); */
}

.token-section .MuiGrid-root.MuiGrid-container {
  max-width: 1270px;
  margin: 0 auto;
}

.token-section-heading h3 label {
  font-size: 30px;
  position: relative;
  right: 10px;
}

.token-section-left-inner-left span {
  font-size: 20px;
  font-weight: 700;
  background: #fff;
  padding: 19px 36px;
  border-radius: 14px;
  box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3);
  position: relative;
  z-index: 9;
}

.token-section-left-outer {
  display: flex;
  align-items: center;
}

.token-section-left-inner span {
  white-space: nowrap;
}

.token-section-left-inner span {
  color: rgba(57, 89, 255, 1);
  font-weight: 600;
}

.token-section-left-inner {
  display: flex;
  flex-direction: column;
  padding: 30px 15px 30px 100px;
  background: rgba(57, 89, 255, 0.07);
  position: relative;
  top: -3px;
  right: 65px;
  border-radius: 10px;
  gap: 5px;
}

.token-section-left-inner label {
  white-space: nowrap;
  font-size: 13px;
}

.tokenomics {
  margin-top: 70px !important;
}

.solutions img {
  width: 100%;
}

.solutions-inner>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.solutions-inner>div span {
  font-size: 20px;
  font-weight: 700;
  font-family: "Space Grotesk", sans-serif;
}

.solutions-inner p {
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 0px;
}

.solutions-inner {
  padding-right: 25px;
  margin-bottom: 50px;
}

h4.solutions-head {
  font-weight: 600;
  font-size: 40px;
  max-width: 590px;
  margin-bottom: 60px;
}

.token-section.roadmap-section h3 span {
  font-weight: 500;
}

.token-section.roadmap-section {
  padding: 100px 0;
}

p.roadmap-top-para {
  margin-top: 0;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}

p.Total-Budget-Allocation {
  color: #3245ee;
  margin-top: 50px;
  font-weight: 600;
}

.roadmap-top-para-Total-Budget-Allocation {
  padding-left: 50px;
}

.roadmap-inner-phase .token-section-left-inner {
  left: 80px;
  right: 0;
  background: rgba(57, 89, 255, 0.07);
}

.roadmap-inner-phase .token-section-left-inner-left {
  position: absolute;
}

.roadmap-inner-phase .token-section-left-inner ul {
  padding-left: 0px;
  margin: 0px;
  line-height: 27px;
}

.roadmap-inner-phase .token-section-left-inner ul li {
  font-family: "Inter", sans-serif;
  color: #3959ff;
  font-weight: 500;
}

.roadmap-inner-phase {
  min-height: 550px;
}

.roadmap-inner-phase.phase-1,
.roadmap-inner-phase.phase-3 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.token-section.roadmap-section .tokenomics {
  position: relative;
}

.roadmapline {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -146px;
}

.roadmapline img {
  width: 100%;
}

.roadmap-inner-phase.phase-1 {
  justify-content: flex-end;
  position: relative;
  right: -15px;
}

.roadmap-inner-phase.phase-2 {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
  top: -85px;
  left: 15px;
}

.roadmap-inner-phase.phase-4 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  right: 159px;
}

a.canvasjs-chart-credit {
  display: none;
}

.token-section-left-outer.token-section-left-outer-thore {
  position: relative;
  z-index: 9;
}

.tokenomics-table-part-td,
.tokenomics-table-part-head {
  display: flex;
  justify-content: center;
}

.tokenomics-table-part-td>div,
.tokenomics-table-part-head>div {
  width: 30%;
  font-size: 16px;
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
}

.tokenomics-table-part-td>div:nth-child(2),
.tokenomics-table-part-head>div:nth-child(2) {
  text-align: center;
}

.tokenomics-table-part-head>div {
  padding: 10px 0;
  font-weight: 600;
  color: #000;
}

.tokenomics-table-part-td {
  padding: 8px 0;
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  border-radius: 3px;
  box-shadow: 0px 7px 80px -12px rgba(85, 102, 255, 1);
  margin-top: 8px;
}

.tokenomics-table-part-td>div {
  font-size: 13px;
}

.Token-allocation-div {
  padding: 20px;
  border-radius: 15px;
  background: rgba(185, 196, 255, 1);
}

.Token-allocation-div>label {
  max-width: 390px;
  font-size: 24px;
  font-weight: 600;
}

.Token-allocation-div p {
  line-height: 27px;
}

.CanvasJSChart {
  height: 100%;
  display: flex;
  align-items: center;
}

.tokenomics-table-part-td,
.tokenomics-table-part-head {
  display: flex;
  justify-content: center;
}

.tokenomics-table-part-td>div,
.tokenomics-table-part-head>div {
  width: 30%;
  font-size: 16px;
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
}

.tokenomics-table-part-td>div:nth-child(2),
.tokenomics-table-part-head>div:nth-child(2) {
  text-align: center;
}

.tokenomics-table-part-head>div {
  padding: 10px 0;
  font-weight: 600;
  color: #000;
}

.tokenomics-table-part-td {
  padding: 8px 0;
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  border-radius: 3px;
  box-shadow: 0px 7px 80px -12px rgba(85, 102, 255, 1);
  margin-top: 8px;
}

.tokenomics-table-part-td>div {
  font-size: 13px;
}

.Token-allocation-div {
  padding: 20px;
  border-radius: 15px;
  background: rgba(185, 196, 255, 1);
}

.Token-allocation-div>label {
  max-width: 390px;
  font-size: 24px;
  font-weight: 600;
}

.Token-allocation-div p {
  line-height: 27px;
}

.CanvasJSChart {
  height: 100%;
  display: flex;
  align-items: center;
}

span.round-circle-1,
span.round-circle-2,
span.round-circle-3,
span.round-circle-4,
span.round-circle-5,
span.round-circle-6,
span.round-circle-7 {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.tokenomics-table-part-td>div:first-child {
  display: flex;
  align-items: center;
}

/*** timer **/

.dhms-outer {
  display: flex;
  justify-content: center;
  gap: 40px;
  background: #000;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 1);
  margin-top: 10px;
}

.dhms {
  display: flex;
  flex-direction: column;
}

.dhms label {
  font-size: 14px;
  margin-bottom: 5px;
}

.dhms span {
  font-size: 24px;
  line-height: 24px;
}

/** common button style **/

a.common-button-link {
  position: relative;
  overflow: hidden;
}

a.common-button-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #02167e;
  transition: all 0.4s ease-in-out;
  transform: translateX(-100%);
}

a.common-button-link span {
  position: relative;
}

a.common-button-link:hover::before {
  transform: translateX(0);
}

/*** hover effect  **/

.howthrn-inner-block:hover {
  transform: scale(1.1);
  border: 1px solid #fff;
  z-index: 9;
  position: relative;
}

.howthrn-inner-block {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  cursor: pointer;
  border: 1px solid transparent;
  height: 100%;
}

.popper-eth .MuiPaper-root {
  background: #000;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 0px 15px;
}

.FundAllocation img {
  width: 450px;
}

.popper-eth div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0px;
  cursor: pointer;
}

/** equity **/

.token-section-right.equity-ai-blockchain .token-section-heading h3 span {
  font-size: 80px;
  line-height: 90px;
  text-align: left !important;
}

.token-section-right.equity-ai-blockchain .token-section-heading h3 {
  justify-content: flex-start !important;
  text-align: left !important;
  align-items: flex-start;
  margin-bottom: 0px;
  right: 0px;
}

.token-section-right.equity-ai-blockchain .token-section-heading h3 label {
  right: 0px;
}

.What-is-Thorecoin-outsmart.equity-conclusion .MuiGrid-root.MuiGrid-container {
  max-width: 1270px;
  background: url("./images/equity-conclusion.webp");
  background-size: cover;
  background-position: center;
  padding-top: 130px;
  padding-bottom: 130px;
}

.What-is-Thorecoin-outsmart.equity-conclusion h3 {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  color: #fff !important;
  margin: 0px;
}

.equity-conclusion p {
  margin-top: 30px;
  margin-bottom: 0px !important;
  color: #fff !important;
  line-height: 25px;
}

.What-is-Thorecoin-outsmart.equity-conclusion {
  margin: 50px 0;
}

.token-section-right.equity-ai-blockchain .token-section-heading p {
  line-height: 25px;
  margin: 25px 0;
}

.equity-banner-right-img img {
  width: 100%;
}

.banner-Thorecoin.banner-Thorecoin-equity {
  background: none;
}

.banner-Thorecoin.banner-Thorecoin-equity h3 {
  font-family: "Inter", sans-serif;
  font-size: 40px;
}

.equity-overview {
  background: url("./images/overview.webp");
  background-size: cover;
  background-position: center;
  padding-top: 80px;
  padding-bottom: 230px;
}

.equity-overview .MuiGrid-root.MuiGrid-container {
  max-width: 1270px;
  margin: 0 auto;
}

.banner-Thorecoin.banner-Thorecoin-equity .MuiGrid-root.MuiGrid-container {
  padding-bottom: 0px;
}

.banner-Thorecoin.banner-Thorecoin-equity {
  height: auto;
}

.equity-overview .outsmart-inner-thorecoin-right label {
  font-size: 20px;
  border-bottom: 3px solid rgba(57, 89, 255, 1);
  padding-bottom: 10px;
  margin-bottom: 30px;
  display: inline-block;
}

.equity-overview .outsmart-inner-thorecoin-right h4 {
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.equity-overview .outsmart-inner-thorecoin-right p {
  line-height: 25px;
}

h4.equity-overview-verical-heading span {
  font-size: 70px;
  background: -webkit-linear-gradient(rgba(42, 61, 237, 1),
      rgba(100, 114, 243, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h4.equity-overview-verical-heading {
  margin: 0px;
  padding-top: 0px;
  text-align: right;
  margin-bottom: 140px;
}

.equity-overview-verical .MuiGrid-root.MuiGrid-container {
  max-width: 1270px;
  margin: 0 auto;
}

.vertical-card-head {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0px;
  background: rgba(57, 89, 255, 0.07);
  border-radius: 10px;
}

.vertical-card-head h5 {
  margin: 0px;
  font-size: 22px;
  font-family: "Space Grotesk", sans-serif;
  color: rgba(57, 89, 255, 1);
}

.vertical-card-head .vert-card-head-icon img {
  height: 30px;
  width: auto;
}

.vert-card-head-icon {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -33px;
}

.vertical-inner-card {
  padding: 12px;
  box-shadow: 0px 13.46px 20.94px 0px rgba(57, 89, 255, 0.3);
  margin-right: 0px;
  margin-left: 45px;
  min-height: 396px;
  border-radius: 15px;
}

.vertical-card-body p {
  font-size: 16px;
  line-height: 25px;
}

.vertical-card-outer.vertical-card-2 {
  margin-top: -140px;
}

.vertical-card-outer.vertical-card-1 {
  margin-top: -300px;
  position: relative;
}

.vertical-inner-card {
  background: #fff;
}

.equity-banner-logo {
  display: flex;
  gap: 10px;
}

.equity-banner-logo p {
  position: relative;
  top: 35px;
  color: #000;
  font-weight: 500;
}

.equity-banner-herolines {
  margin-top: -44px;
  margin-left: 78px;
}

.equity-banner-left-img {
  text-align: right;
  margin-top: -58px;
  display: flex;
  justify-content: flex-end;
  gap: 70px;
  margin-right: -60px;
}

.equity-banner-btns a {
  margin-top: 20px;
}

.equity-banner-btns {
  text-align: right;
  padding: 15px 0;
  box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3);
  display: inline-block;
  border-radius: 12px;
}

.equity-banner-btns a {
  display: inline-block;
  margin: 0 15px;
  padding: 10px 60px;
  border-radius: 35px;
  background: rgba(57, 89, 255, 1);
}

.equity-banner-btns-outer {
  text-align: right;
  padding-right: 30px;
}

.token-section.token-section-ai-blockchain .solutions {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.banner-exchange img {
  width: 100%;
  margin-top: -125px;
}

.thore-net-para {
  line-height: 25px;
}

p.ai-powered-para {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0px;
}

/** analytics page style **/

.analytics-hero-section {
  height: 100vh;
  padding-top: 50px;
}

.analytics-hero-section>.MuiBox-root,
.analytics-hero-section>.MuiBox-root>.MuiGrid-root.MuiGrid-container {
  height: 100vh;
}

.analytics-hero-section>.MuiBox-root>.MuiGrid-root.MuiGrid-container {
  align-items: center;
  max-width: 1270px;
  margin: 0 auto;
}

.analytics-head label {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.analytics-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analytics-body {
  background: #fff;
  box-shadow: 0px 18.85px 29.33px 0px rgba(57, 89, 255, 0.1);
  margin-top: 15px;
}

.analytics-body tr td label {
  color: rgba(57, 89, 255, 1);
  font-weight: 500;
  display: inline-block;
  width: 100%;
  font-size: 12px;
}

.analytics-body tr td {
  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.analytics-body table {
  width: 100%;
}

.analytics-body tr td span {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.analytics-body tr td {
  text-align: left;
  padding: 5px 20px;
}

div#analytics-left .analytics-body {
  padding: 15px 10px;
}

/*** price ticker landing page **/

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin {
  height: auto;
}

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div {
  display: flex;
}

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:last-child {
  display: none;
}

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:first-child div:last-child span a {
  margin: 0px;
}

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:first-child div:last-child span a {
  margin: 0px;
  background: rgb(16, 112, 224);
  color: #fff !important;
}

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:first-child>div:nth-child(2) {
  display: flex;
  gap: 20px;
}

.coinmarketcap-currency-widget {
  margin-top: 30px;
}

.landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div {
  align-items: center;
  gap: 40px;
  padding: 0 20px;
  justify-content: center;
}

/**** aI crypto section ***/

.aicrypto img {
  width: 100%;
}

.token-section-heading.ai-crypto-head h3 span {
  font-size: 80px;
  line-height: 110px;
}

.token-section-heading.ai-crypto-head {
  display: flex;
  align-items: center;
  padding-left: 50px !important;
}

.token-section-heading.ai-crypto-head h3 {
  text-align: left;
  align-items: flex-start;
}

.token-section-heading.ai-crypto-head h3 label {
  right: 0;
}

.token-section.ai-crypt0-now-section {
  padding-top: 40px;
}

.aicryto-img img {
  width: 100%;
}

.cards-ai-crypto {
  padding: 50px;
  box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3);
  border-radius: 15px;
  margin-top: 30px !important;
}

.cards-ai-crypto .token-section-left {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.cards-ai-crypto .token-section-left h4,
.cards-ai-crypto .token-section-left p,
.cards-ai-crypto .token-section-left h5,
.cards-ai-crypto .token-section-left h6 {
  width: 100%;
  text-align: left;
}

.cards-ai-crypto .token-section-left h5 {
  color: #3959ff;
  font-size: 22px;
  margin: 0;
}

.cards-ai-crypto .token-section-left h6 {
  font-size: 20px;
  margin: 15px 0;
}

.card_inner h4 {
  color: #000;
  font-size: 24px !important;
  margin-bottom: 10px !important;
}

.card_inner {
  border-radius: 13.92px;
  background: linear-gradient(163deg, #b4e8ff -106.2%, #fff 88.47%);
  /* box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3); */
  padding: 30px 25px;
  margin-bottom: 20px;
}

.scroll-div {
  overflow-y: scroll;
  height: 250px;
}

.card_inner p {
  color: #000;
  font-size: 18px;
  margin: 0;
}

.cards-ai-crypto .token-section-left h4 {
  font-size: 40px;
  margin: 0px;
}

.cards-ai-crypto .token-section-left p {
  font-size: 16px;
  line-height: 27px;
}

.aicryto-img {
  padding: 30px;
}

.smart-finance-crypto {
  padding: 100px 0 50px;
}

.smart-finance-crypto .MuiGrid-root.MuiGrid-container {
  max-width: 1145px;
  margin: 0 auto;
}

.smart-finance-crypto .MuiGrid-root.MuiGrid-container h3 {
  margin-top: 0px;
  font-size: 40px;
  text-align: center;
  font-family: "Inter", sans-serif;
  margin-bottom: 60px;
}

.sfca-inner p {
  color: #000;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.sfca {
  text-align: center;
}

.sfca-inner {
  padding: 30px 60px;
  box-shadow: 0px 39.34px 61.19px 0px rgba(57, 89, 255, 0.3);
  border-radius: 15px;
  height: 75%;
}

.buy-with-two {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.buy-with-head span {
  display: inline-block;
  padding: 8px 30px;
  border-radius: 8px;
  background: rgba(198, 207, 255, 1);
  font-weight: 500;
}

.buy-with-head {
  text-align: center;
  width: 100%;
  margin-top: 100px;
}

.landingbannerright img {
  width: 100%;
}

/*** responsive landing - presale ***/

@media (max-width: 575.98px) {

  .MuiStack-root.presale-coin-selected-btns button,
  .MuiStack-root.presale-coin-selected-btns a {
    font-size: 12px !important;
    padding: 4px 8px;
  }

  .fot-ul {
    padding-left: 8px !important;
  }

  .footer-section ul {
    padding-left: 0px !important;
  }

  .presale-plan-in-button {
    margin-left: 0px !important;
  }

  .equity-banner-left-img a {
    margin-top: 15px !important;
    padding: 15px 0px !important;
  }

  .footer-1 h5 {
    font-size: 20px !important;
  }

  .footer-1 {
    justify-content: space-evenly !important;

  }

  .raelt-tt,
  .real-desc {
    text-align: center !important;
  }

  .ex-main-desc {
    font-size: 14px !important;
    max-width: 95% !important;
  }

  .bg-img-card {
    padding: 20px !important;
  }

  .f11 {
    text-align: center;
  }

  .realt {
    width: 100% !important;
  }

  .dhms-outer {
    gap: 25px !important;
  }

  .header-section {
    top: 0px !important;
  }

  ul.header-menu {
    width: 90% !important;
  }

  .border-circle {
    position: unset !important;
    padding: 90px 0px !important;
    width: 100% !important;
    font-size: 14px !important;
    clip-path: circle(40% at 50% 50%) !important;
  }

  .ket-feat-ex {
    font-size: 35px !important;
  }

  .realt.dem2 {
    width: 100% !important;
    padding: 10px !important;
  }

  .supo {
    width: 100% !important;
    height: 100% !important;
  }

  .inetr-sub {
    line-height: 27px !important;
  }

  .intergen-text,
  .deeopsight-te,
  .security {
    font-size: 30px !important;
  }

  .datasec,
  .datadesc {
    max-width: 100% !important;
  }

  .flex-label {
    font-size: 11px;
  }

  .ex-main-head,
  .ex-welcome {
    max-width: 90% !important;
    font-size: 25px !important;
    line-height: 48px !important;
  }

  .ket-feat-ex,
  .ket-feat-2 {
    text-align: center !important;
  }

  .rebust.new2 {
    width: auto !important;
    padding: 50px 20px !important;
  }

  .display-2.join.aos-init {
    flex-direction: column !important;
  }

  .join-tex-bt {
    font-size: 25px !important;
    line-height: 32px !important;
  }

  .phone img {
    width: 100% !important;
    height: 100% !important;
  }

  .ex-join {
    font-size: 40px !important;
  }

  .join-desc {
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .launch-text {
    font-size: 30px !important;
  }

  .robust-banr {
    padding: 30px !important;
  }

  .comp {
    font-size: 35px !important;
  }

  .thore-net-sup {
    font-size: 30px !important;
  }

  .launch-desc li {
    font-size: 10px !important;
    margin-right: unset !important;
  }

  .display-1.robust-new {
    justify-content: center !important;
  }
}

@media (min-width: 575.98px) and (max-width: 991.98px) {
  .ex-main-head {
    font-size: 40px !important;
    /* max */
  }

  .join-tex-bt {
    font-size: 32px !important;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .border-circle {
    /* border-radius: 50%; */
    background-color: #1649ff;
    clip-path: circle(25% at 50% 50%);
    padding: 120px !important;
    position: unset !important;
    width: 250px !important;
  }
}

@media (max-width: 991.98px) {
  .banner-logo {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 30px;
  }

  .banner-logo img {
    position: static;
  }
}

@media (min-width: 1200px) and (max-width: 1349.98px) {
  .banner-logo {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 30px;
  }

  .banner-logo img {
    position: static;
  }
}

@media (max-width: 991.98px) {
  .banner-text h1 {
    font-size: 110px;
  }

  .banner-logo {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 30px;
  }

  .banner-logo img {
    position: static;
  }

  .secu.x1 .clp-div,
  .secu.x2 .clp-div {
    position: unset !important;
  }

  .who-is-budu-video video {
    width: 100%;
    height: 100%;
    transform: rotate(0deg);
  }

  .who-is-budu-video {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .banner-img {
    margin-top: 0;
  }

  .banner-img img {
    height: auto;
    width: 100%;
  }

  .buduwhotop {
    margin: 0;
  }

  .buduwhotop img {
    width: 100%;
  }

  img.whobuduboard {
    display: none;
  }

  .who-is-budu-video {
    margin-top: -150px;
  }

  .presale-plan-in {
    left: 0;
    padding: 10px;
  }

  .presale-plan {
    background: #fbe9e8;
  }

  .presale-steps-icon {
    position: relative;
  }

  .presale-steps-icon.peresaleicon1,
  .presale-steps-icon.peresaleicon2,
  .presale-steps-icon.peresaleicon3 {
    left: 0;
    bottom: 0;
  }

  .card-landing.card-common {
    padding: 20px;
  }

  .presale-structure.fund-allocation h3.presale-structure-3 {
    position: relative;
    top: 0;
  }

  .presale-structure.fund-allocation {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
  }

  .FundAllocation img {
    width: 100% !important;
  }

  .banner-section-landing .MuiGrid-root.MuiGrid-container {
    padding-top: 100px;
  }

  .presale-structure {
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgba(83, 166, 224, 1);
  }

  .FundAllocation img {
    width: 100%;
  }

  .fund-allocation-process {
    flex-wrap: wrap;
    padding-top: 0px;
  }

  .fund-allocation-process-even-step {
    top: 0;
    flex-direction: column;
  }

  .seven-companies-inner {
    flex-wrap: wrap;
  }

  .seven-companies-inner div {
    padding: 10px;
  }

  .who-is-bubu-dudu {
    margin-top: 0;
  }

  .presale-form {
    margin-top: 100px;
  }

  legend.phase-even-left-align,
  legend.phase-even-right {
    margin-top: 40px;
  }

  .card-landing.card-common {
    box-shadow: none;
  }

  .presale-structure .MuiGrid-root.MuiGrid-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* .footer-section .MuiGrid-root.MuiGrid-container {
  padding-left: 15px;
  padding-right: 15px;
} */

  .MuiStack-root.presale-coin-selected-btns {
    gap: 10px;
  }

  /*** presale **/

  .banner-Thorecoin {
    height: auto;
  }

  .banner-Thorecoin .MuiGrid-root.MuiGrid-container {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .banner-Thorecoin .MuiGrid-root.MuiGrid-container h1 {
    font-size: 40px;
  }

  .inner-thorecoin:nth-child(even) {
    top: 0;
    transform: scale(1);
  }

  .What-is-Thorecoin-outsmart {
    margin: 0px 0 50px;
  }

  .What-is-Thorecoin-outsmart .outsmartbgimg {
    position: relative;
    bottom: 0;
    right: 0;
  }

  .What-is-Thorecoin-outsmart .outsmartbgimg img {
    height: auto;
    width: 100%;
  }

  .What-is-Thorecoin-outsmart .MuiGrid-root.MuiGrid-container {
    padding: 20px;
  }

  .token-section-heading h3 span {
    font-size: 100px;
    line-height: 100px;
  }

  .token-section-left-outer.token-section-left-outer-thore {
    flex-direction: column;
  }

  .token-section-left-inner {
    right: 0;
    top: 0;
    padding-left: 30px;
  }

  .roadmap-inner-phase {
    min-height: auto;
  }

  .roadmapline {
    display: none;
  }

  .roadmap-inner-phase.phase-1 {
    justify-content: flex-start;
    position: relative;
    right: 0;
  }

  .roadmap-inner-phase .token-section-left-inner-left {
    position: relative;
  }

  .roadmap-inner-phase.phase-2 {
    position: relative;
    top: 0;
    left: 0;
  }

  .roadmap-inner-phase.phase-4 {
    position: relative;
    right: 0;
  }

  .token-section.roadmap-section .token-section-left-outer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .roadmap-inner-phase.phase-2 {
    justify-content: flex-start;
  }

  .token-section.roadmap-section .tokenomics {
    gap: 30px;
  }

  .roadmap-top-para-Total-Budget-Allocation {
    padding: 0 15px;
  }

  .What-is-Thorecoin-outsmart.howthrn .MuiGrid-root.MuiGrid-container {
    padding: 0px 15px 0 0;
    background: transparent;
  }

  .token-section.token-section-ai-blockchain .solutions {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }




  .token-section-right.equity-ai-blockchain .token-section-heading h3 span {
    font-size: 50px;
    line-height: 60px;
  }

  .equity-banner-logo {
    flex-wrap: wrap;
    margin-left: -24px;
  }

  .equity-banner-logo p {
    top: 0;
    margin: 0 30px;
  }



  .equity-banner-left-img {
    margin-right: 0px;
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }

  .equity-banner-left-img img {
    height: 75px;
  }

  .equity-banner-btns {
    display: flex;
  }

  .equity-banner-btns-outer {
    padding: 15px;
    position: relative;
    left: -7px;
  }

  .equity-banner-btns a {
    margin: 0 5px;
  }


  h4.equity-overview-verical-heading span {
    font-size: 50px;
  }

  .banner-exchange img {
    width: 100%;
    margin-top: 0;
  }

  .ket-feat-2 {
    font-size: 35px !important;
    line-height: 35.97px !important;
  }

  .advancetrade {
    font-size: 30px !important;
  }

  .join-te {
    font-size: 25px !important;
  }

  .ex-card-1 {
    height: auto !important;
  }

  .display-1 {
    flex-wrap: wrap !important;
  }

  .launchpad-banner-1 {
    height: auto !important;
  }

  .launchpad-banner-1 .display-2 {
    flex-direction: column !important;
  }

  .blogth img {
    width: 100% !important;
    height: 170px !important;
  }

  .intergen .display-2 {
    flex-direction: column !important;
  }

  /* 
  .intergen-inner-main {
    padding: 50px 0px !important;
    max-width: 100% !important;
    margin: auto !important;
  } */

  .bordersf,
  .bordersf2 {
    display: none;
  }

  .defi,
  .defi2 {
    margin: 25px auto !important;
    width: 80% !important;
  }
}

@media (max-width: 1199.98px) {
  .mobile-btns {
    display: flex !important;
    margin-top: 20px !important;
  }

  .vertical-card-outer.vertical-card-1 {
    margin-top: 0px;
  }

  h4.equity-overview-verical-heading {
    margin-bottom: 50px;
  }

  .vertical-card-outer.vertical-card-2 {
    margin-top: 0;
  }

  .desktop-btns {
    display: none !important;
  }

  .vertical-inner-card {
    margin: 15px;
    min-height: auto;
  }

  .vert-card-head-icon {
    left: 10px;
  }


  .equity-banner-left-img {
    margin-right: 0px !important;
  }

  .mobile-menu-close-icon {
    display: block !important;
  }

  div#header-left {
    flex-direction: column;
    align-items: flex-start;
  }

  ul.header-menu {
    flex-direction: column;
    gap: 5px;
    margin-top: 0px;
    position: fixed;
    height: 100vh;
    width: 83%;
    background: #fff;
    left: 0px;
    top: 0;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 999;
  }

  ul#open-menu-id {
    transform: translateX(-0%);
  }

  ul.header-menu li {
    text-align: left;
  }

  .header-section {
    top: 0;
    position: sticky;
  }

  .logobudu svg {
    display: block !important;
    font-size: 20px;
  }

  div#header-left .logobudu {
    width: 100%;
    justify-content: space-between;
  }

  ul.header-menu li a {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  ul.header-menu li:nth-child(7) a {
    border-bottom: none !important;
  }

  li.presalemenu::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: url("./images/presale-menu.webp");
    left: 45px;
    background-size: contain;
    top: 10px;
  }

  li.mobile-menu-close-icon {
    text-align: right !important;
  }

  li.mobile-menu-close-icon svg {
    font-size: 25px;
  }

  .inner-thorecoin:nth-child(even),
  .header-section .MuiGrid-root.MuiGrid-container {
    box-shadow: none !important;
  }

  .seven-companies-inner img {
    height: 25px;
  }

  .seven-companies-inner {
    padding: 20px;
  }

  .MuiGrid-container {
    max-width: 100% !important;
  }

  div#presale-form-thorecoin-banner-right-form-id .presale-form.thorecoin-banner-right-form {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px !important;
    width: 95% !important;
  }

  .presale-form-for-presale {
    width: 90% !important;
    margin: 0 auto;
  }

  .MuiStack-root.presale-coin-selected-btns.presale-coin-selected-btns-three-btns {
    flex-wrap: wrap;
    /* justify-content: flex-start; */
  }

  .What-is-Thorecoin {
    padding: 100px 0;
  }

  .landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:first-child {
    flex-direction: column;
  }

  .landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div {
    flex-direction: column;
  }

  .buy-with-two {
    flex-direction: column;
  }

  .aicryto-img {
    padding: 0px;
  }

  .cards-ai-crypto {
    padding: 20px;
  }

  .token-section-heading.ai-crypto-head h3 span {
    font-size: 60px;
    line-height: 66px;
  }

  .landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:first-child>div:nth-child(2)>span {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 13px;
  }

  .landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div>div:first-child>div:nth-child(2) {
    flex-direction: column;
    width: 100% !important;
  }

  .landing-page-main.presale-mail-page.landing-page-main-page .banner-Thorecoin .coinmarketcap-currency-widget>div {
    padding: 0px !important;
  }

  .robust-banr {
    position: unset !important;
  }

  .display-2.robo {
    flex-direction: column !important;
    width: fit-content;
    margin: auto;
  }

  .display-1.aos-init {
    /* flex-direction: column !important; */
  }

  .robust-right {
    margin-right: 0px !important;
    flex-direction: column !important;
    margin-bottom: 20px;
  }

  .prime img,
  .robo-im img,
  .robo2 img,
  .join-im img,
  .robust img,
  .rbro3 img,
  .blogr1 img,
  .secu img {
    width: 100% !important;
    height: 100% !important;
  }

  .securi,
  .display-2.educt {
    flex-direction: column !important;
    align-items: center !important;
  }

  .secu.x2 {
    margin-top: 0px !important;
    /* text-align: center !important; */
  }

  .security {
    text-align: center;
  }

  .spport {
    flex-direction: column !important;
  }

  .bodf {
    display: none !important;
  }

  .intergen {
    background: #1649ff !important;
  }

  .intergen-inner-main {
    padding: 50px 0px !important;
    max-width: 100% !important;
    margin: auto !important;
  }

  .inner-thorecoin:nth-child(even) {
    top: 0px !important;
    transform: unset !important;
  }
}

@media (max-width: 767.98px) {
  .community {
    font-size: 35px !important;
  }

  .clp-div {
    clip-path: unset !important;
  }

  .equity-banner-logo img {
    width: 100%;
  }

  .equity-banner-herolines {
    display: none;
  }

  .equity-banner-left-img {
    margin-top: 0px !important;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #6472f3;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d5d8fc;
}

.scroll-div::-webkit-scrollbar {
  width: 0px;
  height: 3px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.scroll-div:hover::-webkit-scrollbar {
  width: 2px !important;
}

.scroll-div::-webkit-scrollbar-track {
  background: none;
}

/*Sankar Exchange-css*/

.advancetrade {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 61.97px;
  /* letter-spacing: -1.1269999742507935px; */
  text-align: center;
}

.ex-card-1 {
  box-shadow: 2px 20px 50px 0px #00000012;
  border-radius: 12px;
  width: 250px;
  padding: 20px 30px;
  height: 260px;
}

.ex-c-desc {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin: 10px 0px;
}

.ex-c-title {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin: 10px 0px;
}

.display-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.ex-card-1 img {
  margin: 15px 15px;
}

.launchpad-banner-1 {
  /* background: #1649FF; */
  border-radius: 12px;
  background-position: center;
  background-image: url("./images/launch-1.png");
  background-size: cover;
  width: 80%;
  margin: 100px auto;
  position: relative;
  height: 350px;
}

.display-2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  gap: 30px;
}

.phone img {
  width: 350px;
  height: 430px;
  position: relative;
  /* top: -15%; */
  margin-top: -15%;
}

.launch-text {
  font-family: "Inter", sans-serif;
  font-size: 45px;
  font-weight: 600;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  color: #fff;
  margin: 35px 0px 0px 10px;
}

.launch-desc li {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #fff;
  margin-right: 21%;
}

.ex-join {
  font-family: "Inter", sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  color: #3959ff;
  margin: 15px 0px;
}

.join-desc {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  /* margin: auto;
  max-width: 75%; */
}

.join-im img {
  width: 350px;
  height: 330px;
}

.display-2.join {
  align-items: center !important;
  margin: 30px 0px;
}

.primary-trade {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30.33px;
  text-align: left;
  padding: 50px 15px;
  border-radius: 12px;
  box-shadow: 0px 13.46px 20.94px 0px #3959ff4d;
}

.prime img {
  width: 480px;
  height: 480px;
}

.joinuus-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(45deg, #202036 25%, #1a3b8b 100%);
  height: 450px;
}

.join-tex-bt {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  color: #fff;
  max-width: 85%;
  margin: auto;
}

.join-brn button {
  background-color: #fff;
  border-radius: 10px;
  width: fit-content;
  color: #3959ff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
  padding: 10px 25px;
  margin-top: 30px;
  text-align: center;
}

.robo-im img {
  width: 500px;
  height: 500px;
}

.robo-im {
  box-shadow: 0px 27px 42px 0px #3959ff4d;
  border-radius: 12px;
  padding: 20px 40px;
}

.comp {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  margin: 15px 0px;
}

.thore-net-sup {
  font-family: "Inter", sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  color: #3959ff;
  margin: 15px 0px;
}

.compd-desc li {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
}

.comp-main {
  background: url("./images/comp.png");
  /* background: url(http://localhost:3000/static/media/comp.9579a2c….png); */
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.rebust {
  width: 250px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
}

.display-2.robo {
  align-items: center !important;
  border: 1px solid #3959ff;
  padding: 0px !important;
  border-radius: 15px;
  position: relative;
}

.robust img {
  width: 480px;
  height: 600px;
}

.robust-banr {
  box-shadow: 0px 27px 42px 0px #3959ff4d;
  border-radius: 12px;
  padding: 60px;
  color: #3959ff;
  width: 250px;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  position: absolute;
  left: 20%;
  z-index: 12;
}

.robust-right {
  margin-right: 50px;
}

.ex-ban1 {
  background: url("./images/exban1.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  align-items: center;
}

.widget {
  width: 70px;
  height: 70px;
  /* background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%); */
  border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0px 7px 40px -12px rgba(85, 102, 255, 1);
}

.widget img {
  height: 70px;
}

.bot_head {
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  color: #fff;
  padding: 10px;
  border-radius: 10px 10px 0 0px;
  padding: 24px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget_chart h2 {
  display: flex;
  align-items: center;
  gap: 15px;
}

.min-icon svg:hover {
  background: rgba(0, 36, 92, 0.16);
  border-radius: 50px;
  padding: 0px;
}

.min-icon svg {
  font-size: 35px;
  cursor: pointer;
}

.offline-message span {
  font-size: 16px;
}

.offline-message {
  margin-top: -20px;
  color: #fff;
  padding: 14px 45px 12px;
  position: relative;
  background-size: 100% calc(100% + 12px);
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  margin-bottom: 10px;
}

.offline-message span:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 2px);
  background: rgb(88, 183, 67);
  border-radius: 50%;
  left: 28px;
}

.offline-message span:after {
  content: "";
  position: absolute;
  width: calc(100% + 0px);
  bottom: -8px;
  left: 0px;
  border-image-source: url("./images/after-widget.png");
  border-image-slice: 0 0 100%;
  border-image-width: 0 0 15px;
  border-image-repeat: stretch;
  border-width: 0px 0px 15px;
  border-bottom-style: solid;
  border-color: initial;
  border-top-style: initial;
  border-left-style: initial;
  border-right-style: initial;
}

.chat-foot {
  position: absolute;
  bottom: 3px;
  left: 5px;
  border-top: 1px solid #dbdfe6;
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ex-welcome {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  text-align: center;
}

.ex-main-head {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 80.41px;
  text-align: center;
  max-width: 70%;
  margin: auto;
  text-transform: uppercase;
  /* color: #0F0FFF; */
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: 0px 5.58px 63.8px -9.57px #5566FF; */
  /* box-shadow: 0px 5.58px 63.8px -9.57px #5566FF; */
}

.ex-main-desc {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: center;
  max-width: 70%;
  margin: auto;
}

.ket-feat-ex {
  font-family: "Inter", sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  color: #3959ff;
  margin: 10px 0px;
}

.ket-feat-2 {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 61.97px;
  letter-spacing: -1.1269999742507935px;
  text-align: left;
  margin: 10px 0px;
}

.robo2 img {
  width: 620px;
  height: 680px;
}

.rebust.new2 {
  width: 180px;
  padding: 100px 20px;
}

.display-1.robust-new {
  justify-content: flex-start;
}

.margin-top {
  margin-top: 50px !important;
}

/*Blog sankar*/

.insight-text {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 48.41px;
  text-align: left;
  /* background: linear-gradient(90deg, #2A3DED 0%, #6472F3 100%); */
  margin: 10px 0px;
}

.revo {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  text-align: left;
  /* color: #000; */
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #2a3ded 0%, #6472f3 100%);
  margin: 10px 0px;
}

.blogr1 img {
  width: 600px;
  height: 600px;
}

.revo-desc {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
  margin: 15px 0px;
}

.blogth img {
  width: 700px;
  height: 300px;
}

.realt {
  box-shadow: 2px 20px 50px 0px #00000012;
  border-radius: 12px;
  width: 300px;
  /* max-width: 70%; */
  padding: 20px 40px;
}

.raelt-tt {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 61.97px;
  text-align: left;
  margin: 15px 0px;
}

.real-desc {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
}

.display-1.react2 {
  gap: 40px !important;
}

.ket-feat-ex span {
  color: #000 !important;
}

.realt.dem {
  width: auto !important;
}

.ket-feat-ex.lines {
  border-bottom: 4px solid #3959ff;
  /* border-width: 60%; */
  /* border-bottom-width: 60% !important; */
  width: fit-content;
}

.realt.dem2 {
  box-shadow: unset !important;
  width: 50%;
}

.deepinsght {
  background: #1649ff;
  border-radius: 12px;
}

.deeopsight-te {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 40.33px;
  text-align: left;
  margin: 15px 0px;
  color: #fff;
}

.deop-tc {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 61.97px;
  text-align: left;
  margin: 10px 0px;
  color: #fff;
}

.despdesc li {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
  margin: 0px 0px;
  color: #fff;
}

.community {
  font-family: "Inter", sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 46.33px;
  text-align: center;
  color: #1649ff;
  margin: 30px;
}

.rbro3 img {
  width: 650px;
  height: 470px;
}

.padd {
  padding: 50px 20px;
}

.supo {
  width: 300px;
  height: 250px;
}

.supo2 {
  width: 300px;
  /* height: 250px; */
}

.display-1.spport {
  justify-content: space-evenly !important;
  /* border: 2px solid #1649FF */
}

.border {
  border: 2px solid #1649ff;
  padding: 15px;
  border-radius: 8px;
  max-width: 75%;
  margin: 50px auto;
}

.bodf {
  height: 300px;
  border-right: 2.5px dashed #1649ff;
}

.nopadcont.MuiGrid-root.MuiGrid-container {
  padding: 50px 0px !important;
}

.educt {
  border-radius: 12px;
  /* box-shadow: 0px 27px 42px 0px #3959FF4D; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px 50px;
  position: relative;
  margin-top: 100px;
}

.border-circle {
  /* border-radius: 50%; */
  background-color: #1649ff;
  clip-path: circle(25% at 50% 50%);
  /* clip-path: circle(50% ); */
  padding: 250px;
  /* height: 100px; */
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 20%;
  /* right: 0; */
  top: -60%;
}

.security {
  font-family: "Inter", sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 30.33px;
  text-align: left;
  margin: 15px 0px;
}

.clp-div {
  clip-path: polygon(26% 15%, 80% 15%, 70% 100%, 15% 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  background-color: #e8e8e8;
  padding: 25px;
  /* background-color: #2A3DED; */
}

.datasec {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 61.97px;
  text-align: left;
  margin: 15px auto;
  padding: 0px 30px;
  max-width: 50%;
}

.datadesc {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
  margin: 15px auto 15px auto;
  padding: 0px 30px;
  max-width: 50%;
}

.secu img {
  width: 500px;
  height: 300px;
}

.secu.x2 {
  margin-top: 250px;
  text-align: right;
}

.secu.x1 .clp-div {
  position: relative;
  top: -150px;
  right: -150px;
}

.secu.x2 .clp-div {
  position: relative;
  top: -150px;
  left: -150px;
}

.defi {
  box-shadow: 0px 27px 42px 0px #3959ff4d;
  padding: 20px;
  border-radius: 12px;
  width: 50%;
  margin: 25px 0px 25px auto;
  background: #fff;
  position: relative;
  z-index: 100;
}

.defi2 {
  box-shadow: 0px 27px 42px 0px #3959ff4d;
  padding: 20px;
  border-radius: 12px;
  width: 50%;
  margin: auto 0px 25px 0px;
  position: relative;
  background: #fff;
  z-index: 100;
}

.defi-head {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  margin: 15px 0px;
}

.defi-desc {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
}

.bordersf {
  width: 400px;
  height: 250px;
  border: 2.5px dashed #000;
  border-right: unset !important;
  position: absolute;
  top: 20%;
  left: 18%;
  /* z-index: 1; */
}

.bordersf2 {
  width: 400px;
  height: 250px;
  border: 2.5px dashed #000;
  border-left: unset !important;
  position: absolute;
  top: 20%;
  right: 18%;
  /* z-index: 1; */
}

.relative {
  position: relative;
}

.border-circle-2 {
  /* border-radius: 50%; */
  background-color: #1649ff;
  clip-path: circle(25% at 50% 50%);
  /* clip-path: circle(50% ); */
  padding: 100px;
  /* height: 100px; */
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 20%;
  /* right: 0; */
  top: -0%;
}

.chat-foot input {
  width: 320px;
  height: 15px;
  padding: 25px 9px;
}

.chat-foot fieldset {
  border: none;
}

.chat-foot input::placeholder {
  color: #616161;
}

.chat-foot .send_btn {
  border-radius: 28px;
  color: #fff;
  background: linear-gradient(47.82deg, #2a3ded 0%, #6472f3 100%);
  padding: 12px;
  display: flex;
  box-shadow: 0px 7px 40px -12px rgba(85, 102, 255, 1);
  cursor: pointer;
  min-width: 50px;
  min-height: 50px;
}

.widget_content.default {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.widget_content.default p {
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

/* .default-2 p{
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
} */
.widget_content.right {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  float: right;
  margin-right: 8px;
}

.message-body_default {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px 0px;
}

.widget_content.left {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  float: left;
  margin-left: 8px;
}

.widget_content {
  padding: 10px 16px;
  border-radius: 20px;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 80%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
  margin-top: 15px;
}

.message_flex-grid {
  overflow-y: scroll;
  /* height: 300px; */
  height: 395px;
  margin-top: 5px;
  display: flex;
  flex-direction: column-reverse;
}

.r1-i img {
  width: 200px;
}

.r1-i {
  position: absolute;
  left: -25%;
  top: 25%;
}

.r1-dem {
  position: absolute;
  right: -38% !important;
  top: 30%;
}

.r1-dem img {
  width: 300px;
}

.intergen {
  background: url("././images/intergen.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 570px;
  margin-top: 100px;
}

.intergen-text {
  font-family: "Inter", sans-serif;
  /* font-family: Inter; */
  font-size: 40px;
  font-weight: 600;
  line-height: 30.33px;
  text-align: center;
  color: #fff;
}

.inetr-sub {
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 61.97px;
  text-align: left;
  color: #fff;
  margin: 15px 0px;
}

.intergen-inner-main {
  padding: 50px 30px 30px 120px;
  max-width: 60%;
  margin: auto;
}

.inter-desc li {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.33px;
  text-align: left;
  color: #fff;
}

.card-value-overview .MuiGrid-container {
  max-width: 1440px;
  margin: 0 auto;
}

.card-inner-box {
  box-shadow: 0px 13.46px 20.94px 0px #3959ff4d;
  padding: 15px;
  border-radius: 5px;
  width: 90%;
  background: #fff;
}

.card-inner-box label {
  color: #3959ff;
  font-size: 22px;
  font-weight: 500;
  /* margin-bottom: 9px; */
}

.card-inner-box p {
  color: #1e1e1e;
  font-size: 26px;
  font-weight: 500;
  margin: 0;
  /* margin-top: 7px; */
  line-height: 1.7;
}

.bg-img-card {
  background: url(./images/bg-cards.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px;
}

.chat-foot .send_btn:hover {
  background: #fff;
  box-shadow: rgba(0, 77, 255, 0.5) 0px 4px 24px;
}

.chat-foot .send_btn:hover svg {
  color: #000;
  color: rgb(0, 125, 252);
}

@media (max-width: 1999.98px) {
  .message_flex-grid {
    height: 200px;
  }

  .message_flex-grid.insig {
    height: 300px !important;
  }
}

.equity-banner-left-img a {
  background: transparent;
}

.display-4 {
  display: flex !important;
  justify-content: flex-start !important;
  gap: 10px;
}

.insight-widget .widget_chart {
  position: relative !important;
  width: 100% !important;
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.insight-widget .widget_chart.insign {
  position: unset !important;
  width: 100% !important;
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chat-foot.insi {
  position: unset !important;
}

.widget_content.default.insight p {
  max-width: unset !important;
}

.message-body_default.new2 {
  justify-content: flex-start !important;
}

.blog-card {
  margin: 20px 10px;
  display: flex;
  justify-content: center;
}

.blog-c-title {
  font-family: "Inter", sans-serif !important;
  /* font-family: Inter; */
  font-size: 25px !important;
  font-weight: 600 !important;
  line-height: 30.33px;
  text-align: left;
  /* color: #fff; */
}

.blog-c-para {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 61.97px;
  text-align: left;
  /* color: #fff; */
  margin: 15px 0px;
}

.blog-c-btn button {
  padding: 10px 35px !important;
  border-radius: 35px;
  box-shadow: 0px 27px 42px 0px rgba(57, 89, 255, 0.3);
  background: #000 !important;
  margin: 25px 0px;
}

.blog-c-btn {
  text-align: center !important;
}

.thore-coin-h1 h1 {
  font-family: "Inter", sans-serif !important;
  text-align: left;
  margin-bottom: 25px;
  font-size: 48px !important;
}

.thore-coin-h1 h3 {
  font-size: 33px !important;
}

.thore-coin-h1 h4 {
  font-size: 27px !important;
}

.thore-coin-h1 h5 {
  font-size: 22px !important;
}

.thore-coin-h1 h6 {
  font-size: 18px !important;
}

.para-desc,
.para-desc ol li {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  line-height: 30px;
}

.para-desc ol li {
  margin: 20px 0px;
}

.card-trans .MuiCardMedia-root {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  cursor: pointer;
}

.card-trans:hover .MuiCardMedia-root {
  transform: scale(1.05);
}

.blog-inner,
.blog {
  padding: 15px !important;
}

.pageniation ul {
  justify-content: center;
}

.koin-inr {
  background: none !important;
  padding: 15px 0px !important;
}

.footer-section .MuiGrid-root {
  padding: 20px !important;
}

.outsmart-inner-thorecoin-right.bcg,
.howthrn.bcg {
  background: #ffffff1a;
  backdrop-filter: blur(20px);
  padding: 15px;
  border-radius: 12px;
}

.solutions img {
  max-width: 80%;
  height: 100%;
}

.solutions {
  text-align: center;
  position: sticky;
  top: 250px;
}

.back-arrow-div {
  text-align: left;
  border-radius: 50%;
  background: #6462f4;
  box-shadow: -17px 17px 61px #514fc6,
    17px -17px 61px #7775ff;
  width: fit-content;

  cursor: pointer;
}


.back-arrow-div svg {
  color: #fff;
  padding: 6px;
  display: flex;
}

.button {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.button span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card-trans:hover .button::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: rgba(57, 89, 255, 1);
  transition: all 0.475s;
}

.card-mda {
  background-position: top !important;
}

.cursor {
  cursor: pointer;
}

ul {
  list-style: none;
}

.example-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}

.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}

.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}

.example-2 .icon-content a:hover {
  color: white;
}

.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="linkedin"] .filled,
.example-2 .icon-content a[data-social="linkedin"]~.tooltip {
  background-color: #0274b3;
  font-family: "Inter", sans-serif !important;
}

.example-2 .icon-content a[data-social="github"] .filled,
.example-2 .icon-content a[data-social="github"]~.tooltip {
  background-color: #1877F2;
  font-family: "Inter", sans-serif !important;
}

.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"]~.tooltip {
  background: linear-gradient(45deg,
      #405de6,
      #5b51db,
      #b33ab4,
      #c135b4,
      #e1306c,
      #fd1f1f);
  font-family: "Inter", sans-serif !important;
}

.example-2 .icon-content a[data-social="youtube"] .filled,
.example-2 .icon-content a[data-social="youtube"]~.tooltip {
  background-color: #068537;
  font-family: "Inter", sans-serif !important;
}

.example-2 .icon-content a[data-social="copy"] .filled,
.example-2 .icon-content a[data-social="copy"]~.tooltip {
  background-color: #670685;
  font-family: "Inter", sans-serif !important;
}